@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Prevent padding and borders from affecting dimensions */
}

html,
body {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Comfortaa", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
blockquote,
strong {
  font-family: "Comfortaa", cursive;
}

.document {
  size: 21cm 29.7cm;
  margin: 30mm 45mm 30mm 45mm;
  /* to centre page on screen*/
}

.storeimg-container {
  width: 200px;
  height: 50px;
}

.hero {
  .section-inner {
    padding-top: $hero--padding-t__mobile;
    padding-bottom: $hero--padding-b__mobile;
  }
}

.hero-inner {
  // Affects only hero full
  > .hero-content + .hero-figure,
  > .hero-figure + .hero-content {
    margin-top: $hero--inner-padding-v_mobile;
  }

  > .hero-figure {
    > a {
      display: inline-flex;
      // prevents stretching
      align-items: center;
      vertical-align: top;
    }
  }
}

.hero-content-wrapper {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.hamburger-menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;

  .hamburger-icon {
    display: none;
  }

  .menu-items {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    transition: opacity 0.3s ease, visibility 0s linear 0.3s;
	padding: 1rem;
  }

}

@media screen and (max-width: 767px) {
  .hero-content-wrapper {
    grid-template-columns: repeat(1, 1fr);

    p {
      order: 1;
      text-align: center !important;
    }
    img {
      order: -1;
    }
  }

  .hamburger-menu {
    position: relative;
    width: 40px;
    height: 40px;
	margin-top: 1rem;
	margin-right: 1rem;
    cursor: pointer;
	margin-left: auto;

    .hamburger-icon {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .line {
        width: 24px;
        height: 2px;
        background-color: #fff;
        margin-bottom: 4px;
        transition: transform 0.3s ease;
      }
    }

    &.menu-open .line:nth-child(1) {
      transform: rotate(45deg) translate(4px, 4px);
    }

    &.menu-open .line:nth-child(2) {
      opacity: 0;
    }

    &.menu-open .line:nth-child(3) {
      transform: rotate(-45deg) translate(4px, -4px);
    }

    .menu-items {
      position: absolute;
      top: 100%;
      right: -1rem;
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 0.75rem;
      background-color: #151719;
      list-style-type: none;
      padding: 16px;
      margin: 0;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease, visibility 0s linear 0.3s;
      z-index: 99;

      a,
      div,
      div select {
        width: 100%;
      }
    }

    &.menu-open .menu-items {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
    }
  }
}

@include media("<=medium") {
  .hero {
    .split-wrap {
      .split-item {
        .split-item-content {
          margin-bottom: $hero--inner-padding-v_mobile;
        }
      }

      &.invert-mobile {
        .split-item {
          .split-item-image {
            margin-bottom: $hero--inner-padding-v_mobile;
          }
        }
      }
    }
  }
}

@include media(">medium") {
  .hero {
    .section-inner {
      padding-top: $hero--padding-t__desktop;
      padding-bottom: $hero--padding-b__desktop;
    }
  }

  .hero-inner {
    // Affects only hero full
    > .hero-content + .hero-figure,
    > .hero-figure + .hero-content {
      margin-top: $hero--inner-padding-v_desktop;
    }
  }
}
